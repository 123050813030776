import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import {
    Layout,
    useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import DealSection from "../components/DealSection"

import { useProjects } from "../hooks/ProjectsState"

export default function NotFoundPage({ data }) {
    const translate = useTranslate()
    const [mergedProjects] = useProjects(
        data.projects ? data.projects.edges.map((p) => p.node) : []
    )

    return (
        <Layout
            navbarFixed={true}
            navbarTransparent={true}
            customSeo={{
                title: translate["404.title"].text,
                description: translate["404.subtitle"].text,
                image: null,
                noIndex: true,
                removeSuffix: false,
                alternateLanguages: [],
            }}
        >
            <DealSection
                titleNode={
                    translate["404.title"].text
                        ? {
                              childMarkdownRemark: {
                                  htmlWithoutWrapper:
                                      translate["404.title"].text,
                              },
                          }
                        : null
                }
                textNode={
                    translate["404.subtitle"].text
                        ? {
                              childMarkdownRemark: {
                                  html: translate["404.subtitle"].html,
                              },
                          }
                        : null
                }
                projects={mergedProjects}
                order={1}
            />
        </Layout>
    )
}

export const query = graphql`
    query {
        projects: allDozenProject(
            filter: { status: { in: ["open", "restricted"] } }
        ) {
            edges {
                node {
                    ...Project
                }
            }
        }
    }
`
